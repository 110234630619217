import api, { Api, ApiResponse } from '.'

const noOp = () => {} // eslint-disable-line

export class TransactionChecker {
  private successCallback: Function = noOp
  private failureCallback: Function = noOp
  private errorCallback: Function = noOp
  private timer: number | undefined = undefined

  check(id: string) {
    this.timer = setInterval(async () => {
      try {
        const response: ApiResponse = await (api as Api).transactionById(id)

        if (!response.isSuccessful) {
          clearInterval(this.timer)
          this.timer = undefined
          return this.errorCallback(response)
        }

        if (response.data.status !== 'pending') {
          clearInterval(this.timer)
          this.timer = undefined

          if (response.data.status === 'failed') {
            this.failureCallback(response.data)
          } else {
            this.successCallback(response.data)
          }
        }
      } catch (error) {
        clearInterval(this.timer)
        this.timer = undefined

        this.errorCallback(error)
      }
    }, 1000)

    setTimeout(() => {
      if (this.timer) {
        clearInterval(this.timer)
        this.errorCallback(new Error('too_long'))
      }
    }, 120000)
  }

  cancel() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = undefined
    }
  }

  onSuccess(successCallback: Function): this {
    this.successCallback = successCallback
    return this
  }

  onFailure(failureCallback: Function): this {
    this.failureCallback = failureCallback
    return this
  }

  onError(errorCallback: Function): this {
    this.errorCallback = errorCallback
    return this
  }

  static create() {
    return new TransactionChecker()
  }
}
