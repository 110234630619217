
































































































































































import Vue, { VueConstructor } from 'vue'
import { mapGetters } from 'vuex'
import BalanceCard from '@/components/BalanceCard.vue'
import VRadioInput from '@/components/VRadioInput.vue'
import OtpModal from '@/components/OtpModal.vue'
import VAlert from '@/components/VAlert.vue'

import Organization from '../../../store/models/Organization'
import { Response } from '@vuex-orm/plugin-axios'
import uiState from '../../../ui-state'
import Transaction from '../../../store/models/Transaction'
import { TransactionChecker } from '../../../api/transaction-checker'
import OrganizationBalance from '../../../store/models/OrganizationBalance'
import AccountMixin from '../../../mixins/accountMixin'

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      otpModal: InstanceType<typeof OtpModal>
    }
  } & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard,
    VRadioInput,
    OtpModal,
    VAlert
  },

  mixins: [AccountMixin],

  data() {
    return {
      model: {
        amount: 1,
        otm: 'mtn'
      },
      transactionChecker: TransactionChecker.create(),
      uiState: uiState([
        'idle',
        'creating',
        'created',
        'validating',
        'validated',
        'transaction_success',
        'transaction_failed',
        'checking_status',
        'error_low_balance',
        'error_no_validator',
        'error_request_otp',
        'error_low_virtual_account_balance'
      ])
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['user']),

    organization(): Organization {
      return Organization.loggedIn() as Organization
    },

    maxCreditAmount(): number {
      return this.organization.refundBalance(this.model.otm)
    },

    cardSubtitle(this: any): string {
      return `Solde au ${this.$options.filters.date(
        new Date(),
        'DD/MM/YYYY HH:mm'
      )}`
    },

    creating(): boolean {
      return this.uiState.in(['creating', 'created'])
    },

    showOtpModal(): boolean {
      return this.uiState.in(['error_request_otp', 'validating'])
    },

    showLowBalanceError(): boolean {
      return this.uiState.is('error_low_balance')
    },

    showLowVirtualAccountBalanceError(): boolean {
      return this.uiState.is('error_low_virtual_account_balance')
    },

    showSuccessAlert(): boolean {
      return this.uiState.is('transaction_success')
    },

    validating(): boolean {
      return this.uiState.is('validating')
    },

    checkingStatus(): boolean {
      return this.uiState.in(['validated', 'checking_status'])
    }
  },

  mounted(this: any) {
    this.transactionChecker
      .onSuccess(async (transaction: Transaction) => {
        this.uiState.set('transaction_success')

        await OrganizationBalance.decrementBalance({
          organizationId: this.organization.id,
          type: 'refund',
          amount: transaction.amount,
          otm: transaction.otm
        })

        await OrganizationBalance.incrementBalance({
          organizationId: this.organization.id,
          type: 'default',
          amount: transaction.amount,
          otm: transaction.otm
        })
      })
      .onFailure(() => {
        this.uiState.set('transaction_failed')
      })
  },

  beforeDestroy(this: any) {
    if (this.transactionChecker) {
      this.transactionChecker.cancel()
    }
  },

  methods: {
    async onSubmit() {
      this.uiState.set('creating')

      try {
        const response: Response = await Transaction.api().fundFnmDefaultAccount(
          this.model.amount,
          this.model.otm
        )

        this.uiState.set('created', response.response.data)

        await this.validateTransaction()
      } catch (error) {
        if (!error.response) {
          console.error(error)
        }

        if (error.response.status === 406) {
          this.uiState.set('error_low_balance', error.response.data.balance)
        }

        if (error.response.status === 422) {
          this.uiState.set(
            'error_low_virtual_account_balance',
            error.response.data.data
          )
        }
      }
    },

    async validateTransaction(otp?: string) {
      try {
        if (otp) {
          this.uiState.set('validating', this.uiState.data)
        }

        await Transaction.api().validateTransaction(this.uiState.data.id, otp)

        this.uiState.set('validated', this.uiState.data)

        this.transactionChecker.check(this.uiState.data.id)
      } catch (error) {
        if (!error.response) {
          console.error(error)
        }

        if (error.response.status === 400) {
          this.uiState.set('error_request_otp', this.uiState.data)
          switch (error.response.data.code) {
            case 'otp_invalid':
              this.$refs.otpModal.setError('Le code entré est invalide')
              break
          }
        } else if (error.response.status === 403) {
          this.uiState.set('error_request_otp', this.uiState.data)
        }
      }
    }
  }
})
